import item from './item'

const dataMontono2 = [
    {
        img: item.item73,
        id: 'multi-clover-10',
    },
    {
        img: item.item72,
        id: 'star-chaser',
    },
    {
        img: item.item64,
        id: '10-clover-fire',
    },
    {
        img: item.item71,
        id: 'eternal-hearts-10',
    },
    {
        img: item.item77,
        id: 'sweet-respins',
    },
    {
        img: item.item63,
        id: 'only-anime',
    },
    {
        img: item.item70,
        id: 'clover-royale',
    },
    {
        img: item.item76,
        id: 'farm-fiesta',
    },
]

export default dataMontono2;