import item from './item'

const dataMontono2 = [
    {
        img:item.item79,
        id: 'sticky-tiki',
    },
    {
        img:item.item78,
        id: 'hot-rush-triple-star',
    },
    {
        img:item.item77,
        id: 'sweet-respins',
    },
    {
        img:item.item76,
        id: 'farm-fiesta',
    },
    {
        img:item.item75,
        id: 'reel-x5',
    },
    {
        img:item.item73,
        id: 'multi-clover-10',
    },
    {
        img:item.item74,
        id: 'divine-strike',
    },
    {
        img:item.item72,
        id: 'star-chaser',
    },
    {
        img:item.item71,
        id: 'eternal-hearts-10',
    },
    {
        img:item.item70,
        id: 'clover-royale',
    },
    {
        img:item.item69,
        id: 'cosmic-sevens',
    },
    {
        img:item.item68,
        id: 'funky-fruits',
    },
    {
        img:item.item67,
        id: 'christmas-delight',
    },
    {
        img:item.item66,
        id: 'juicy-heat-20',
    },
    {
        img:item.item65,
        id: '27-double-fruits',
    },
    {
        img:item.item63,
        id: 'only-anime',
    },
    {
        img: item.item64,
        id: '10-clover-fire',
    },
    {
        img:item.item62,
        id: '81-double-magic',
    },
    {
        img:item.item61,
        id: 'spooky-spins',
    },
    {
        img:item.item60,
        id: '5-wild-fire',
    },
    {
        img:item.item59,
        id: 'volcano-hot',
    },
    {
        img:item.item58,
        id: 'volcano-crown',
    },
    {
        img:item.item57,
        id: '100-clover-fire',
    },
    {
        img:item.item56,
        id: 'dice-double',
    },
    {
        img:item.item55,
        id: 'double-clover-fire',
    },
    {
        img:item.item54,
        id: '81-vegas-crown',
    },
    {
        img:item.item53,
        id: 'apollo-27-classic',
    },
    {
        img:item.item52,
        id: 'spin-up',
    },
    {
        img:item.item51,
        id: 'hot-rush-fruit-lines',
    },
    {
        img:item.item50,
        id: 'book-of-scorpio',
    },
    {
        img:item.item49,
        id: 'hot-rush-both-ways',
    },
    {
        img:item.item48,
        id: 'caps-and-crowns',
    },
    {
        img: item.item47,
        id: '5-clover-fire-lnc',
    },
    {
        img: item.item46,
        id: 'hot-rush-stars-deluxe',
    },
    {
        img: item.item45,
        id: 'slot-royale',
    },
    {
        img: item.item44,
        id: 'lady-triple-fortune',
    },
    {
        img: item.item43,
        id: 'hot-rush-crown-burst',
    },
    {
        img: item.item42,
        id: 'chilli-double',
    },
    {
        img: item.item37,
        id: 'jolly-presents',
    },
    {
        img: item.item39,
        id: '20-wild-crown',
    },
    {
        img: item.item38,
        id: 'wild-trail',
    },
    {
        img: item.item40,
        id: '40-jokers-free-games',
    },
    {
        img: item.item41,
        id: 'moon-dog',
    },
    {
        img: item.item36,
        id: 'wild-heart-beat',
    },
    {
        img: item.item35,
        id: '40-fruit-frenzy',
    },
    {
        img: item.item34,
        id: '20-fruit-frenzy',
    },
    {
        img: item.item33,
        id: 'chilli-hot-5',
    },
    {
        img: item.item32,
        id: '40-hot-joker',
    },
    {
        img: item.item31,
        id: 'fear-of-dark',
    },
    {
        img: item.item30,
        id: 'clover-blast-5',
    },
    {
        img: item.item29,
        id: 'hot-hot',
    },
    {
        img: item.item28,
        id: 'blazing-heat',
    },
    {
        img: item.item27,
        id: 'heat-double',
    },
    {
        img: item.item26,
        id: 'super-lucky',
    },
    {
        img: item.item25,
        id: '40-fire-cash',
    },
    {
        img: item.item23,
        id: '20-fire-cash',
    },
    {
        img: item.item22,
        id: '10-wild-santa',
    },
    {
        img: item.item21,
        id: '5-crown-fire',
    },
    {
        img: item.item20,
        id: 'heat-classic-5',
    },
    {
        img: item.item19,
        id: 'book-of-dread',
    },
    {
        img: item.item18,
        id: '10-wild-pumpkin',
    },
    {
        img: item.item17,
        id: 'clover-coin',
    },
    {
        img: item.item2,
        id: '10-wild-crown',
    },
    {
        img: item.item1,
        id: 'mayan-coins',
    },
    {
        img: item.item3,
        id: '40-cash-bells',
    },
    {
        img: item.item4,
        id: '40-clover-fire',
    },
    {
        img: item.item5,
        id: 'action-hot-20',
    },
    {
        img: item.item6,
        id: 'lost-book',
    },
    {
        img: item.item7,
        id: '5-wild-heart',
    },
    {
        img: item.item8,
        id: '40-dice-fire',
    },
    {
        img: item.item9,
        id: '10-wild-diamond',
    },
    {
        img: item.item10,
        id: 'action-hot-40',
    },
    {
        img: item.item11,
        id: '5-clover-fire',
    },
    {
        img: item.item12,
        id: '5-dice-fire',
    },
    {
        img: item.item13,
        id: '50-wild-cash',
    },
    {
        img: item.item14,
        id: '40-wild-dice',
    },
    {
        img: item.item15,
        id: '40-wild-clover',
    },
    {
        img: item.item16,
        id: '10-wild-dice',
    },
];

export default dataMontono2;